import React from "react";
import { useQuery } from "@apollo/client";
import Layout from "components/Layout";
import TimelineView from "components/TimelineView";
import ScheduleDelivery from "components/Delivery/ScheduleDelivery";
import LoadingScreen from "components/LoadingScreen";
import ClientOrderQuery from "./ClientOrderQuery.gql";
import ClientPortalConfigQuery from "./ClientPortalConfigQuery.gql";
import { ORDER_STATUS } from "utils/constants";

const Delivery = () => {
  const { loading, data: clientOrderData } = useQuery(ClientOrderQuery, {
    fetchPolicy: "network-only",
  });

  const { loading: configLoading, data: clientPortalConfigData } = useQuery(
    ClientPortalConfigQuery,
    {
      fetchPolicy: "network-only",
    }
  );

  const isLoading = React.useMemo<boolean>(() => {
    return loading || configLoading;
  }, [loading, configLoading]);

  const isOrderCancelled = React.useMemo<boolean>(
    () => clientOrderData?.clientOrder?.status === ORDER_STATUS.CANCELLED,
    [clientOrderData]
  );
  const isTimelineDisplayed = React.useMemo<boolean>(() => {
    return (
      clientOrderData?.clientOrder?.delivery?.status &&
      clientOrderData?.clientOrder?.status === ORDER_STATUS.VALIDATED
    );
  }, [clientOrderData]);

  if (
    isLoading ||
    !clientOrderData ||
    !clientPortalConfigData ||
    !clientOrderData.clientOrder ||
    !clientPortalConfigData.clientPortalConfig
  ) {
    return <LoadingScreen />;
  }

  return (
    <Layout
      order={clientOrderData.clientOrder}
      clientPortalConfig={clientPortalConfigData.clientPortalConfig}
    >
      {isOrderCancelled ? (
        <div className="cancelled">
          La livraison a été annulée, merci de vous rapprocher de votre magasin
        </div>
      ) : (
        <div className="delivery">
          {isTimelineDisplayed ? (
            <TimelineView delivery={clientOrderData.clientOrder.delivery} />
          ) : (
            <ScheduleDelivery order={clientOrderData.clientOrder} />
          )}
        </div>
      )}
    </Layout>
  );
};

export default Delivery;
